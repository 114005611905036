<template>
  <div class="row mx-0 h-100">
    <div class="col-12 p-4 card_item h-100">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-12 p-0">
          <div class="row m-0 justify-content-center holder" >
            <div class="col-12 p-0 above">
              <div class="row m-0 justify-content-center">
                <div class="col-auto text-center p-0 mb-3">
                  <IconRound size="big" align="center" class="lightgreen">
                    <slot />
                  </IconRound>
                </div>
                <div class="col-12 text-center p-0 mb-3">
                  <div class="card_heading">
                    <slot name="heading" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconRound: defineAsyncComponent(() => import('@/components/IconRound.vue'))
  },
  name: 'AddNewCard'
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  /* box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px; */
  color: var(--normal-color);
}
.card_item:hover {
  background-color: #f4f4f4;
}
.card_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
}
.above {
  z-index: 1;
}
</style>
